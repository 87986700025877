#contact-me-header-div {
    padding-top: 20px;
}

#contact-me-header {
    color: rgb(82, 154, 221);
    font-family: Arial, Helvetica, sans-serif;
    font-size: 40px;
    font-weight: 700;
    text-shadow: 2px 2px rgb(33, 33, 33);
}

#contact-container {
    padding-bottom: 16px;
}

#contact-name {
    color: rgb(136, 135, 135);
    text-shadow: 2px 2px rgb(41, 40, 40);
}

.contact-item {
    color: white;
    font-size: 20px;
}

/* #linkedin-logo {
} */

#linkedin-logo:hover {
    transform: scale(1.2);
    transition: all .2s ease-in-out;
}

#github-logo {
    padding-left: 24px;
}

#github-logo:hover {
    transform: scale(1.2);
    transition: all .2s ease-in-out;
}

/* #facebook-logo {
    padding-left: 10px;
} */

#facebook-logo:hover {
    transform: scale(1.2);
    transition: all .2s ease-in-out;
}

/* #resume-logo {
    padding-left: 10px;
} */

#resume-logo:hover {
    transform: scale(1.2);
    transition: all .2s ease-in-out;
}

