.navbar {
    position: static;
    align-items: center;
    padding-left: 30px;
}

.navbar-nav.navbar-center {
    position: absolute;
    left: 50%;
    transform: translatex(-50%);
}