body {
    background-color: #36454F;
}

#projects-title {
    color: white;
    /* font-family: Arial, Helvetica, sans-serif; */
    font-family: 'Roboto', sans-serif;
    font-size: 36px;
    text-shadow: 2px 2px rgb(33, 33, 33);
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 24px;
}

.card {
    border: solid 2px darkgrey;
}

.col-md-3 {
    padding-left: 100px;
}

/* .card:hover {
    border: solid 2px darkgrey;
    transform: scale(1);
    transition: all .2s ease-in-out;
    opacity: 75%;
} */

.card-body {
    background-color: #36454F;
}

#sure-vote-title {
    color: #79D4FF;
}

#remember-them-title {
    color: #E6D906;
}

#streaming-guide-title {
    color: #49A1D7;
}

#password-generator-title {
    color: #99CA99;
}

#github-finder-title {
    color: #5a925a;
}



.card-text {
    color: white;
}

#website-button {
    padding-right: 10px;
}

#website-button:hover {
    padding-right: 10px;
}

#github-button {
    padding-left: 10px;
}

#github-button:hover {
    padding-left: 10px;
}

.btn {
    color: white;
    background-color: #36454F;
    border: solid 2px #374045;
}
