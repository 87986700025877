#home-container {
    padding-left: 20px;
    padding-top: 40px;
    /* display: flex; */
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    align-content: center;
    justify-content: center;
}

.background-image {
  position: relative;
  background-repeat: no-repeat;
}

#project-text {
  /* position: absolute; */
  display: none;
  /* top: 25%;
  left: 33%; */
}

#project-text:hover {
  position: absolute;
  display: block;
  top: 25%;
  left: 33%;
}

h4 {
  color: white;
}

#name-is-span {
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-weight: 600;
}

/* #profile-link {
  
} */

#projects-link {
  padding-left: 20px;
}

/* #contact-link {

} */

/* #profile-icon {
  
} */

#profile-icon:hover {
  transform: scale(1.2);
  transition: all .2s ease-in-out;
}

#projects-icon {
  padding-left: 20px;
}

#projects-icon:hover {
  transform: scale(1.2);
  transition: all .2s ease-in-out;
}

#contact-icon {
  padding-left: 10px;
}

#contact-icon:hover {
  transform: scale(1.2);
  transition: all .2s ease-in-out;
}

#name-span {
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 80px;
  font-weight: 900;
  padding-top: -20px;
  text-shadow: 3px 3px 6px rgb(33, 33, 33);
}

.tagline-span {
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-shadow: 2px 2px rgb(33, 33, 33);
  background-color: rgba(37, 37, 37, 0.551);
}

#designer-span {
  color: rgb(90, 166, 221);
}

#developer-span {
  color: rgb(204, 115, 115);
  padding-left: 115px;
}

#dad-span {
  padding-left: 115px;
  color: rgb(88, 170, 88);
}

#dude-span {
  padding-left: 115px;
  color: rgb(182, 178, 178);
}

#profile-header {
  padding-top: 18px;
  padding-left: 20px;
}

/* #profile-header:hover {
  display: block;
  color: white;
}  */

#projects-header {
  padding-top: 16px;
  padding-left: 32px;
}

/* #projects-header:hover {
  display: block;
  color: white;
} */

#contact-header {
  padding-left: 12px;
  padding-top: 12px;
}

/* #contact-header:hover {
  display: block;
  color: white;
}  */

#homepage-text {
  padding-top: 12px;
  color: white;
  font-size: 18px;
  text-align: justify;
  text-shadow: 2px 2px rgb(33, 33, 33);
  width: 610px;
}

#profile-img-home {
  padding-top: 40px;
  padding-left: 75px;
  border-radius: 3px;
}

/* Media breaks for reactivity */

/* @media (max-width: 1399px) {
  #name-span {
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 40px;
    font-weight: 600;
    padding-top: -20px;
    text-shadow: 3px 3px 6px rgb(33, 33, 33);
  }
  
} */