.container {
    position: relative;
    margin: 0px;
    padding: 0px;
}

#computer-image :hover {
    opacity: 0%;
}

#phone-image :hover {
    opacity: 0%;
}

#text-area {
    border: solid white 1px;
}

.navbar {
    position: static;
    align-items: center;
    padding-left: 30px;
}

.navbar-nav.navbar-center {
    position: absolute;
    left: 50%;
    -webkit-transform: translatex(-50%);
            transform: translatex(-50%);
}
#home-container {
    padding-left: 20px;
    padding-top: 40px;
    /* display: flex; */
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    align-content: center;
    justify-content: center;
}

.background-image {
  position: relative;
  background-repeat: no-repeat;
}

#project-text {
  /* position: absolute; */
  display: none;
  /* top: 25%;
  left: 33%; */
}

#project-text:hover {
  position: absolute;
  display: block;
  top: 25%;
  left: 33%;
}

h4 {
  color: white;
}

#name-is-span {
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-weight: 600;
}

/* #profile-link {
  
} */

#projects-link {
  padding-left: 20px;
}

/* #contact-link {

} */

/* #profile-icon {
  
} */

#profile-icon:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  transition: all .2s ease-in-out;
}

#projects-icon {
  padding-left: 20px;
}

#projects-icon:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  transition: all .2s ease-in-out;
}

#contact-icon {
  padding-left: 10px;
}

#contact-icon:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  transition: all .2s ease-in-out;
}

#name-span {
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 80px;
  font-weight: 900;
  padding-top: -20px;
  text-shadow: 3px 3px 6px rgb(33, 33, 33);
}

.tagline-span {
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-shadow: 2px 2px rgb(33, 33, 33);
  background-color: rgba(37, 37, 37, 0.551);
}

#designer-span {
  color: rgb(90, 166, 221);
}

#developer-span {
  color: rgb(204, 115, 115);
  padding-left: 115px;
}

#dad-span {
  padding-left: 115px;
  color: rgb(88, 170, 88);
}

#dude-span {
  padding-left: 115px;
  color: rgb(182, 178, 178);
}

#profile-header {
  padding-top: 18px;
  padding-left: 20px;
}

/* #profile-header:hover {
  display: block;
  color: white;
}  */

#projects-header {
  padding-top: 16px;
  padding-left: 32px;
}

/* #projects-header:hover {
  display: block;
  color: white;
} */

#contact-header {
  padding-left: 12px;
  padding-top: 12px;
}

/* #contact-header:hover {
  display: block;
  color: white;
}  */

#homepage-text {
  padding-top: 12px;
  color: white;
  font-size: 18px;
  text-align: justify;
  text-shadow: 2px 2px rgb(33, 33, 33);
  width: 610px;
}

#profile-img-home {
  padding-top: 40px;
  padding-left: 75px;
  border-radius: 3px;
}

/* Media breaks for reactivity */

/* @media (max-width: 1399px) {
  #name-span {
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 40px;
    font-weight: 600;
    padding-top: -20px;
    text-shadow: 3px 3px 6px rgb(33, 33, 33);
  }
  
} */
body {
    background-color: #36454F;
}

#projects-title {
    color: white;
    /* font-family: Arial, Helvetica, sans-serif; */
    font-family: 'Roboto', sans-serif;
    font-size: 36px;
    text-shadow: 2px 2px rgb(33, 33, 33);
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 24px;
}

.card {
    border: solid 2px darkgrey;
}

.col-md-3 {
    padding-left: 100px;
}

/* .card:hover {
    border: solid 2px darkgrey;
    transform: scale(1);
    transition: all .2s ease-in-out;
    opacity: 75%;
} */

.card-body {
    background-color: #36454F;
}

#sure-vote-title {
    color: #79D4FF;
}

#remember-them-title {
    color: #E6D906;
}

#streaming-guide-title {
    color: #49A1D7;
}

#password-generator-title {
    color: #99CA99;
}

#github-finder-title {
    color: #5a925a;
}



.card-text {
    color: white;
}

#website-button {
    padding-right: 10px;
}

#website-button:hover {
    padding-right: 10px;
}

#github-button {
    padding-left: 10px;
}

#github-button:hover {
    padding-left: 10px;
}

.btn {
    color: white;
    background-color: #36454F;
    border: solid 2px #374045;
}

#contact-me-header-div {
    padding-top: 20px;
}

#contact-me-header {
    color: rgb(82, 154, 221);
    font-family: Arial, Helvetica, sans-serif;
    font-size: 40px;
    font-weight: 700;
    text-shadow: 2px 2px rgb(33, 33, 33);
}

#contact-container {
    padding-bottom: 16px;
}

#contact-name {
    color: rgb(136, 135, 135);
    text-shadow: 2px 2px rgb(41, 40, 40);
}

.contact-item {
    color: white;
    font-size: 20px;
}

/* #linkedin-logo {
} */

#linkedin-logo:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    transition: all .2s ease-in-out;
}

#github-logo {
    padding-left: 24px;
}

#github-logo:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    transition: all .2s ease-in-out;
}

/* #facebook-logo {
    padding-left: 10px;
} */

#facebook-logo:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    transition: all .2s ease-in-out;
}

/* #resume-logo {
    padding-left: 10px;
} */

#resume-logo:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    transition: all .2s ease-in-out;
}


.row1-container {
    padding-top: 25px;
}

.row2-container {
    padding-top: 25px;
}

#image_container {
    
    padding-right: 400px;
}

#profile-image {
    border-radius: 1px;
    border: solid white 10px;
    box-shadow: 10px 10px 5px;
}

h1 {
    color: rgb(77, 129, 150);
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
}

#name-header {
    font-family: 'Roboto', sans-serif;
    font-size: 48px;
    color: #7FC764;
    /* color: #02AAEB; */
    font-weight: 600;
    text-shadow: 3px 3px rgb(57, 56, 56);
}

.profile-text {
    color: white;
    text-align: justify;
}

#react-logo {
    border: solid white 1px;
    border-radius: 5px;
    padding-right: 3px;
}

#bootstrap-logo {
    border: solid white 1px;
    border-radius: 5px;
}

#node-logo {
    padding-left: 5px;
    padding-top: 4px;
}

#express-logo {
padding-left: 12px;
}

#mongodb-logo {
    padding-left: 10px;
}

#mern-logo {
    padding-left: 10px;
}

#sql-logo {
    padding-left: 10px;
    padding-top: 4px;
}

