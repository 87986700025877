.container {
    position: relative;
    margin: 0px;
    padding: 0px;
}

#computer-image :hover {
    opacity: 0%;
}

#phone-image :hover {
    opacity: 0%;
}

#text-area {
    border: solid white 1px;
}
